/* istanbul ignore file */

export const GENRE_TYPES = {
	LOAD: "LOAD_GENRE"
};

export const MEDIA_TYPES = {
	LOAD_POPULAR: "LOAD_POPULAR_MOVIES",
	LOAD_UPCOMING: "LOAD_UPCOMING_MOVIES",
	LOAD_TRENDING: "LOAD_TRENDING_MOVIES",
	SET_ACTIVE: "SET_ACTIVE",
	LOAD_KEYWORDS: "LOAD_KEYWORDS",
	LOAD_RECCOMENDATIONS: "LOAD_RECCOMENDATIONS",
	LOAD_RELATED: "LOAD_RELATED",
	CLEAN_ACTIVE: "CLEAN_ACTIVE",
	LOAD_MEDIA_BY_TYPE: "LOAD_MOVIE_BY_TYPE",
	LOAD_MORE_MEDIA_BY_TYPE: "LOAD_MORE_MOVIE_BY_TYPE"
};

export const SEARCH_TYPES = {
	DO_SEARCH: "DO_SEARCH",
	DO_SEARCH_MORE: "DO_SEARCH_MORE"
}

export const AUTH_ACTIONS = {
    SET_TOKEN: "SET_TOKEN",
};